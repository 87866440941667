import React from "react"
import styled from "styled-components"
// import useScript from "src/hooks/useScript"
import { media } from "src/styles/breakpoints"
import Spinner from "src/components/Spinner"

const DonorboxWidget = ({ className }) => {
  // const [loaded, error] = useScript("https://donorbox.org/widget.js")

  return (
    <Wrapper className={className}>
      <Iframe
        allowpaymentrequest=""
        frameBorder="0"
        height="900px"
        name="donorbox"
        scrolling="auto"
        seamless="seamless"
        src="https://donorbox.org/embed/anima-international"
        title="Donorbox"
      ></Iframe>
      <StyledSpinner />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 550px;
  overflow: hidden;
  position: relative;
  background: #fff;
  /* border: 5px solid #fff; */
  border-radius: 3px 3px 0 0;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);
  color: #000;

  ${media.tablet} {
    width: 300px;
  }

  ${media.desktop} {
    width: 370px;
  }

  @media (min-height: 700px) {
    border-radius: 3px;
  }
`

const Iframe = styled.iframe`
  width: calc(100% + 2px);
  max-width: 500px;
  /* min-width: 310px; */
  height: 100%;
  max-height: none !important;
  position: relative;
  top: -1px;
  left: -1px;
  z-index: 1;
`

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
`

export default DonorboxWidget
