import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { animateScroll } from "react-scroll"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle, TertiaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseButton from "src/components/Base/BaseButton"
import BaseIcon from "src/components/Base/BaseIcon"
import DonorboxWidget from "src/components/DonorboxWidget"

const DonatePage = () => {
  const [observed, setObserved] = useState(false)
  const title = "Spare animals a lifetime of suffering"
  const subtitle =
    "Change the fate of farmed animals by supporting us to end animal suffering"

  function onButtonClick() {
    const scrollTop = Math.min(window.innerHeight, window.innerWidth * 0.7)
    animateScroll.scrollTo(scrollTop, {
      duration: 900,
      smooth: "easeInOutQuart",
    })
  }

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <Wrapper className={observed ? "-observed" : ""}>
      <Seo
        title="Donate and change animals’ fate"
        description={subtitle}
        image="/images/hero/donate.jpg"
      />
      <Section>
        <Container>
          <Title as="h1">{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Button onClick={onButtonClick}>
            Contact <small>/</small> U.S. Tax deduction{" "}
            <ButtonIcon name="arrow-down" source="feather" />
          </Button>
        </Container>
        <DonationBoxWrapper wide>
          <DonationBox />
        </DonationBoxWrapper>
      </Section>
      <InfoWrapper>
        <Columns>
          <Column>
            <Contact>
              <ContactInner>
                <ContactTitle>Contact us</ContactTitle>
                <ContactSubtitle>
                  Do you want to make the biggest impact with your gift? We
                  would be happy to talk with you further about how we can be
                  more effective at helping animals together.
                </ContactSubtitle>
                <ContactPerson>
                  <ContactImage src="/images/team/kirsty-henderson.jpg" alt="Kirsty Henderson photo" />
                  <ContactPersonDetails>
                    <ContactName>Kirsty Henderson</ContactName>
                    <ContactPosition>
                      Executive Director for Anima International
                    </ContactPosition>
                    <ContactLink href="mailto:kirsty.henderson@animainternational.org">
                      <ContactLinkIcon name="mail" source="feather" />
                      kirsty.henderson@animainternational.org
                    </ContactLink>
                    <br />
                    <ContactLink href="tel:+447985289753">
                      <ContactLinkIcon name="phone" source="feather" />
                      +44 (0) 7985 289753
                    </ContactLink>
                  </ContactPersonDetails>
                </ContactPerson>
              </ContactInner>
            </Contact>
          </Column>
          <Column>
            <SideInfo>
              <SideInfoTitle>
                <SideInfoIcon name="dollar-sign" source="feather" /> U.S. Tax
                deduction
              </SideInfoTitle>
              <SideInfoText>
                Are you a U.S.-based donor and would you like to make a convenient, tax-deductible donation? You can do so through Karuna Foundation, a <span className="number">501(c)(3)</span> non-profit organization. We will receive 100% of your gift made via Karuna Foundation, minus the processing fees.
              </SideInfoText>
              <AceButton
                label="Donate via Karuna Foundation"
                size="small"
                icon="arrow-right"
                to="https://form.fillout.com/t/vQsa1ouSL3us"
              />
            </SideInfo>
          </Column>
        </Columns>
      </InfoWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Section = styled.div`
  margin-bottom: 150px;
  padding-bottom: 20px;
  position: relative;
  background: url(/images/hero/donate.jpg) 39% center / cover #000;
  color: #fff;

  ${media.tablet} {
    height: 100vh;
    min-height: 670px;
    max-height: 70vw;
    padding-bottom: 0;
    background-position: center center;
  }

  ${media.desktop} {
    margin-bottom: 200px;
  }
`

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-top: 180px;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    div${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  > :nth-child(3) {
    transition-delay: 0.3s;
  }

  > :nth-child(4) {
    transition-delay: 0.45s;
  }

  ${media.tablet} {
    padding-top: 70px;

    @media (min-height: 650px) {
      padding-top: 160px;
    }
  }
`

const Title = styled(PrimaryTitle)`
  max-width: 10em;
  margin-bottom: 30px;
  color: inherit;
`

const Subtitle = styled.p`
  max-width: 240px;
  margin-bottom: 60px;
  color: rgba(255, 255, 255, 0.7);

  ${media.tablet} {
    margin-bottom: 0;
  }
`

const Button = styled.button`
  display: none;
  height: 50px;
  margin-top: 70px;
  margin-left: 4px;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 50px;
  letter-spacing: 0.1em;

  small {
    margin: 0 0.35em;
    font-weight: 200;
  }

  ${media.tablet} {
    display: inline-block;
  }
`

const ButtonIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-left: 10px;
  transition: transform 0.25s;

  ${Button}:hover & {
    transform: translateY(5px);
  }
`

const DonationBoxWrapper = styled(BaseContainer)`
  padding-top: 100px;
  pointer-events: none;

  ${media.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 160px;
    /* padding-bottom: 0; */
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  @media (min-height: 700px) {
    align-items: center;
  }
`

const DonationBox = styled(DonorboxWidget)`
  pointer-events: all;
`

const InfoWrapper = styled(BaseContainer)``

const Columns = styled.div`
  margin: 0 auto 155px;

  ${media.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 560px;
    margin-bottom: 215px;
  }

  ${media.desktop} {
    flex-direction: row;
    width: auto;
  }
`

const Column = styled.div`
  &:last-child {
    margin-top: 150px;
  }

  ${media.tablet} {
    &:first-child {
      min-width: 560px;
    }

    &:last-child {
      margin-top: 150px;
    }
  }

  ${media.desktop} {
    margin-top: 0;

    &:first-child {
      margin-right: 70px;
    }

    &:last-child {
      margin-top: 0;
    }
  }

  ${media.desktopMedium} {
    &:first-child {
      min-width: 700px;
      margin-right: 80px;
    }
  }
`

const Contact = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
  background: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background: #fff;
  }

  ${media.tablet} {
    padding: 60px 60px 70px;
  }

  ${media.desktopMedium} {
    padding: 70px 70px 80px;
  }
`

const ContactInner = styled.div`
  position: relative;
`

const ContactTitle = styled(TertiaryTitle)`
  margin-bottom: 20px;
`

const ContactSubtitle = styled.p`
  margin-bottom: 80px;
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`

const ContactPerson = styled.div`
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};

  ${media.tablet} {
    display: flex;
    align-items: center;
  }
`

const ContactImage = styled.img`
  width: 100px;
  min-width: 100px;
  margin-right: 40px;
  margin-bottom: 30px;
  border-radius: 50%;

  ${media.tablet} {
    margin-bottom: 0;
  }

  ${media.desktopMedium} {
    width: 120px;
    min-width: 120px;
    margin-right: 50px;
  }
`

const ContactPersonDetails = styled.div``

const ContactName = styled.h4`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.07em;
  color: #000;
`

const ContactPosition = styled.p`
  margin-bottom: 10px;
`

const ContactLink = styled.a`
  display: inline-block;
  padding: 2px 0;
  font-size: 10px;

  ${media.tablet} {
    font-size: 11px;
  }

  ${media.desktopMedium} {
    font-size: inherit;
  }
`

const ContactLinkIcon = styled(BaseIcon)`
  opacity: 0.7;
  width: 16px;
  height: 16px;
  margin-right: 15px;
  color: #8c9d9d;
`

const SideInfo = styled.div`
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`

const SideInfoTitle = styled.h4`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  /* line-height: 1.6; */
  letter-spacing: 0.07em;
  color: #000;
`

const SideInfoIcon = styled(BaseIcon)`
  opacity: 0.7;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  position: relative;
  top: -1px;
  color: #8c9d9d;
`

const SideInfoText = styled.p`
  a {
    text-decoration: underline;
  }

  .number {
    font-family: Arial;
  }
`

const AceButton = styled(BaseButton)`
  margin-top: 20px;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    margin-right: -7px;
    position: relative;
    top: -1px;
  }
`

export default DonatePage
