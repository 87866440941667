import React from "react"
import styled from "styled-components"
import spinner from "src/assets/images/spinner.gif"

const Spinner = ({ className }) => {
  return <Element src={spinner} className={className} alt="Loading..." />
}

const Element = styled.img`
  width: 48px;
`

export default Spinner
